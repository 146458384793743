import { z } from 'zod';

const cateringEmailAddressSchema = z.object({
  emailAddress: z.string(),
  ownerName: z.string(),
});

export type CateringEmailAddress = z.infer<typeof cateringEmailAddressSchema>;

export const cateringPhoneNumberSchema = z.object({
  phoneNumber: z.string(),
  ownerName: z.string(),
  providerId: z.string(),
});

export type CateringPhoneNumber = z.infer<typeof cateringPhoneNumberSchema>;

export const cateringSettingsSchema = z.object({
  id: z.string(),
  blockOrders: z.number(),
  cateringDeliveryLeadTime: z.number(),
  cateringDeliveryMinimum: z.number(),
  cateringOrderMaximum: z.number(),
  cateringPickupLeadTime: z.number(),
  cateringRestricted: z.boolean(),
  dateCreated: z.string(),
  dateUpdated: z.string(),
  deliveryEnabled: z.boolean(),
  emailProviders: z.array(cateringEmailAddressSchema),
  maxDeliveryOrdersAllowed: z.number(),
  maximumDeliveryDistance: z.number(),
  maxOrdersAllowed: z.number(),
  maxPickupOrdersAllowed: z.number(),
  pickupEnabled: z.boolean(),
  pickupMinimum: z.number(),
  sameDayCateringOrderMaximum: z.number(),
  smsProviders: z.array(cateringPhoneNumberSchema),
  inStateCateringDeliveryOnly: z.boolean(),
});

export type CateringSettings = z.infer<typeof cateringSettingsSchema>;

export default CateringSettings;
