import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasDopPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasDopPerms, setHasDopPerms] = useState(true);
  const [permsLoaded, setPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.DOP_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.DOP_USER?.length;
      setHasDopPerms(isAuthorized);
      setPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasDopPerms, permsLoaded };
};

export default useHasDopPerms;
