import { z } from 'zod';

const dayHoursSchema = z.object({
  id: z.string(),
  dayNumber: z.number(),
  name: z.string(),
  storeClosed: z.boolean(),
  is24hr: z.boolean(),
  openTime: z.string(),
  closeTime: z.string(),
  deliveryClosed: z.boolean(),
  cateringDeliveryOpenTime: z.string(),
  cateringDeliveryCloseTime: z.string(),
  cateringDeliveryBlackoutEnabled: z.boolean(),
  cateringDeliveryBlackoutStartTime: z.string(),
  cateringDeliveryBlackoutEndTime: z.string(),
  dateCreated: z.string(),
  dateUpdated: z.string(),
});

export type DayHours = z.infer<typeof dayHoursSchema>;

export const storeHoursResponseSchema = z.object({
  appliesToAllCateringHours: z.boolean(),
  appliesToAllStoreHours: z.boolean(),
  monday: dayHoursSchema,
  tuesday: dayHoursSchema,
  wednesday: dayHoursSchema,
  thursday: dayHoursSchema,
  friday: dayHoursSchema,
  saturday: dayHoursSchema,
});

type StoreHours = z.infer<typeof storeHoursResponseSchema>;

export default StoreHours;
