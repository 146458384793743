import { z } from 'zod';

export const scheduledClosureSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  reason: z.string(),
  lastModifiedBy: z.string(),
  lastModifiedAt: z.number(),
});

type ScheduledClosure = z.infer<typeof scheduledClosureSchema>;

export default ScheduledClosure;
