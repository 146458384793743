import { z } from 'zod';

export const limitedCateringHoursResponseSchema = z.object({
  id: z.string(),
  parentId: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  reason: z.string(),
  system_generated: z.boolean(),
  closed: z.boolean(),
  open: z.boolean(),
  openTime: z.string().optional(),
  closeTime: z.string().optional(),
  editDelivery: z.boolean(),
  deliveryClosed: z.boolean(),
  deliveryOpen: z.boolean(),
  deliveryOpenTime: z.string().optional(),
  deliveryCloseTime: z.string().optional(),
  lastModifiedBy: z.string(),
  lastModifiedAt: z.number(),
});

type LimitedCatering = z.infer<typeof limitedCateringHoursResponseSchema>;

export default LimitedCatering;
