import { FC, Suspense, useEffect } from 'react';
import { LoadingIndicator } from '@cfa/react-components';
import { Outlet, useNavigation } from 'react-router-dom';
import { platformToast } from '@cfacorp/ctrl-platform-ui-core-utils';
import { navigateToUrl } from 'single-spa';
import { Overlay } from '@cfacorp/ctrl-platform-shared-react-components';
import ErrorBoundary from '../../ErrorBoundary';
import useHasCaresPerms from '../../hooks/useHasCaresPerms';

const CaresRoot: FC = () => {
  const navigation = useNavigation();
  const isLoading = navigation.state === 'loading';
  const { hasCaresPerms } = useHasCaresPerms();

  useEffect(() => {
    if (!hasCaresPerms) {
      navigateToUrl('/');
      platformToast.next({
        toastInfo: {
          title: 'Error',
          variant: 'error',
          content:
            'You do not have access to the CARES vertical, please contact HELP if needed!',
        },
      });
    }
  }, [hasCaresPerms]);

  return (
    <ErrorBoundary>
      <Overlay isOpen={isLoading}>
        <LoadingIndicator variant="page" />
      </Overlay>
      <Suspense
        fallback={
          <Overlay isOpen>
            <LoadingIndicator variant="page" />
          </Overlay>
        }
      >
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
};

export default CaresRoot;
