import { z } from 'zod';

export const dopLocationSchema = z.object({
  chainwideRecord: z.boolean(),
  conceptCode: z.string(),
  createdBy: z.string(),
  dateCreated: z.string(),
  dateUpdated: z.string(),
  dopChangeVersion: z.number(),
  dopLocationEmployeeLinkChangeVersion: z.number(),
  lambdaChangeVersion: z.number(),
  lambdaLocationEmployeeLinkChangeVersion: z.number(),
  locationName: z.string(),
  locationNumber: z.string(),
  locationStatus: z.string(),
  marketableName: z.string(),
  locationEmployeeLink: z
    .string()
    .url({ message: 'Please enter a valid URL.' }),
  modifiedBy: z.string(),
  temporarilyClosed: z.boolean(),
  temporarilyClosedReason: z.string(),
  temporaryClosedFlagLastUpdated: z.number(),
  temporaryClosedModifiedBy: z.string(),
  temporaryClosedByScheduledClosure: z.boolean(),
  temporaryClosedByScheduledClosureId: z.string(),
  temporaryClosedByEmergencyToggle: z.boolean(),
  version: z.number(),
  configuredInFlyBuy: z.boolean(),
});

type DopLocation = z.infer<typeof dopLocationSchema>;

export default DopLocation;
