import { z } from 'zod';

export const thirdPartyLocationSchema = z.object({
  status: z.string(),
  lastUpdateTimestamp: z.string(),
});

export type ThirdPartyLocation = z.infer<typeof thirdPartyLocationSchema>;

export const thirdpartyOrderSchema = z.object({
  successes: z.number(),
  failures: z.number(),
});

export type ThirdPartyOrder = z.infer<typeof thirdpartyOrderSchema>;

export const thirdPartyMenuSchema = z.object({
  status: z.string(),
  lastSuccessTimestamp: z.string(),
});

export type ThirdPartyMenu = z.infer<typeof thirdPartyMenuSchema>;

export const thirdPartyPartnerSchema = z.object({
  partner: z.string(),
  status: z.string(),
  menu: thirdPartyMenuSchema,
  order: thirdpartyOrderSchema,
  location: thirdPartyLocationSchema,
});

export type ThirdPartyPartner = z.infer<typeof thirdPartyPartnerSchema>;

export const thirdPartyDeliveryStatusSchema = z.object({
  locationNumber: z.string(),
  partners: z.array(thirdPartyPartnerSchema),
});

export type ThirdPartyDeliveryStatus = z.infer<
  typeof thirdPartyDeliveryStatusSchema
>;
