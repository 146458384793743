import { useQuery } from '@tanstack/react-query';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import dopService from '../services/dopService';

export default function useGetPersonalOrderSettings() {
  const selectedStoreNumber = useSelectedStoreNumber();

  const result = useQuery(
    ['getPersonalOrderSettings', selectedStoreNumber],
    () => dopService.getPersonalOrderSettings(selectedStoreNumber),
    {
      enabled: !!selectedStoreNumber.length,
    },
  );

  return result;
}
