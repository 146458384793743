export enum DopDataPoints {
  StoreDetails = 'storeSettings',
  DigitalOrdering = 'digitalOrderingSettings',
  Location = 'location',
  StoreClosures = 'storeClosures',
  LimitedHours = 'specialEvents',
  CaresTiers = 'caresTiers',
  StoreHours = 'week',
  HolidayHours = 'holidays',
  CateringOrderSettings = 'cateringOrderSettings',
  PersonalOrderSettings = 'personalOrderSettings',
  MenuActivations = 'menuActivations',
  EmergencyMenus = 'emergencyMenus',
  EquipmentFailure = 'equipmentFailure',
  DiningOptions = 'diningOptions',
  Localization = 'localization',
}

export const dopDataPointGroups = {
  [DopDataPoints.StoreDetails]: 'Digital Profile',
  [DopDataPoints.DigitalOrdering]: 'Digital Ordering',
  [DopDataPoints.Location]: 'Location',
  [DopDataPoints.StoreClosures]: 'Planned Store Closures',
  [DopDataPoints.LimitedHours]: 'Limited Digital Catering Hours',
  [DopDataPoints.CaresTiers]: 'CARES Tiers',
  [DopDataPoints.StoreHours]: 'Store Hours',
  [DopDataPoints.HolidayHours]: 'Holiday Hours',
  [DopDataPoints.CateringOrderSettings]: 'Catering Settings',
  [DopDataPoints.PersonalOrderSettings]: 'Mobile App Settings',
  [DopDataPoints.MenuActivations]: 'Menu Activations',
  [DopDataPoints.EmergencyMenus]: 'Limited Menu Activations',
  [DopDataPoints.EquipmentFailure]: 'Equipment Failure',
  [DopDataPoints.DiningOptions]: 'Dining Options',
  [DopDataPoints.Localization]: 'Location Page',
} as const;
