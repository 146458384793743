import { z } from 'zod';

export const phoneProviderSchema = z.object({
  emailSuffix: z.string(),
  phoneProviderId: z.string(),
  providerName: z.string(),
});

export type PhoneProvider = z.infer<typeof phoneProviderSchema>;

export default PhoneProvider;
