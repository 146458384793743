import { z } from 'zod';

export const checkInInstructionsDefaultsSchema = z.object({
  name: z.string().optional(),
  destinationType: z.string(),

  driveThruExpressEnabled: z.boolean().optional(),
  driveThruQRCodeEnabled: z.boolean().optional(),
  withParkingSpotNumbers: z.boolean().optional(),
  curbsideAnywhere: z.boolean().optional(),
  hasPickupShelf: z.boolean().optional(),

  specialInstructionsLine1: z.string().optional(),
  specialInstructionsLine2: z.string().optional(),
  specialInstructionsURL: z.string().optional(),
});

export type CheckInInstructionsDefaults = z.infer<
  typeof checkInInstructionsDefaultsSchema
>;
