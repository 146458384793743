import { useQuery } from '@tanstack/react-query';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import dopService from '../services/dopService';

export default function useGetDopLocation(hasDopPerms?: boolean) {
  const selectedStoreNumber = useSelectedStoreNumber();

  const result = useQuery(
    ['getDopLocation', selectedStoreNumber, hasDopPerms],
    () => dopService.getDopLocation(selectedStoreNumber),
    {
      enabled: !!selectedStoreNumber.length,
    },
  );

  return result;
}
