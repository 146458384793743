import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Root from './root.component';

const queryClient = new QueryClient();

export default function PreRoot() {
  return (
    <QueryClientProvider client={queryClient}>
      <Root />
    </QueryClientProvider>
  );
}
