import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasStoreListingPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasStoreListingPerms, setHasStoreListingPerms] = useState(true);
  const [storeListingPermsLoaded, setStoreListingPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.STORE_LISTING_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.STORE_LISTING_USER?.length;
      setHasStoreListingPerms(isAuthorized);
      setStoreListingPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasStoreListingPerms, storeListingPermsLoaded };
};

export default useHasStoreListingPerms;
