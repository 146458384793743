import { FC, ReactNode } from 'react';
import { getBugsnagErrorBoundary } from '@cfacorp/ctrl-platform-ui-core-utils';
import { ErrorPage } from '@cfacorp/ctrl-platform-shared-react-components';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
  const AppBugsnagErrorBoundary = getBugsnagErrorBoundary();

  if (AppBugsnagErrorBoundary) {
    return (
      <AppBugsnagErrorBoundary
        onError={event => (event.unhandled = true)}
        FallbackComponent={ErrorPage}
      >
        <>{children}</>
      </AppBugsnagErrorBoundary>
    );
  }
  return <>{children}</>;
};

export default ErrorBoundary;
