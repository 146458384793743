import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasCaresPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasCaresPerms, setHasCaresPerms] = useState(true);
  const [caresPermsLoaded, setCaresPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.CARES_USER?.length;
      setHasCaresPerms(isAuthorized);
      setCaresPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasCaresPerms, caresPermsLoaded };
};

export default useHasCaresPerms;
