export enum StoreListingDataPoints {
  StoreDetails = 'storeSettings',
  Localization = 'localization',
  Location = 'location',
}

export const storeListingDataPointGroups = {
  [StoreListingDataPoints.StoreDetails]: 'Contact, Social Media, and Amenities',
  [StoreListingDataPoints.Localization]: 'CFA .Com Page',
  [StoreListingDataPoints.Location]: 'Location',
} as const;
