enum RouteConstants {
  HOME = '/',
  DIGITAL_ORDERING_BASE_NAME = '/digitalOrdering',
  STORE_LISTING_BASE_NAME = '/storeListing',
  HOURS = `/hours`,
  CARES_BASE_NAME = '/cares',
  CATERING = '/catering',
  CATERING_NOTIFICATIONS = '/catering/notifications',
  CATERING_LIMITED_SCHEDULE = '/catering/limitedSchedule',
  CONTACT_SOCIAL_MEDIA = '/contactAndSocialMedia',
  AMENITIES = '/amenities',
  HOLIDAY_HOURS = '/hours/holidayHours',
  CLOSURES = '/hours/closures',
  THIRD_PARTY_DELIVERY_BASE_NAME = '/thirdPartyDelivery',
}

export default RouteConstants;
