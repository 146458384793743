import { z } from 'zod';

export const videosSchema = z.object({
  account_id: z.string(),
  id: z.string(),
});

type videos = z.infer<typeof videosSchema>;

export default videos;
