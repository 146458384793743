import { z } from 'zod';

export const storeHolidayHoursSchema = z.object({
  cateringDeliveryBlackoutEndTime: z.string(),
  date: z.string(),
  cateringDeliveryBlackoutStartTime: z.string(),
  cateringDeliveryCloseTime: z.string(),
  cateringDeliveryOpenTime: z.string(),
  cateringDeliveryOffered: z.boolean(),
  parentId: z.string(),
  dateCreated: z.string(),
  operatorOverrideAllowed: z.boolean(),
  name: z.string(),
  closeTime: z.string(),
  closed: z.boolean(),
  open: z.boolean(),
  id: z.string(),
  openTime: z.string(),
  cateringDeliveryBlackoutEnabled: z.boolean(),
});

type StoreHolidayHours = z.infer<typeof storeHolidayHoursSchema>;

export default StoreHolidayHours;
