import { z } from 'zod';

export const storeDetailsResponseSchema = z.object({
  id: z.string(),
  dateCreated: z.string(),
  dateUpdated: z.string(),
  facebookURL: z.string(),
  instagramURL: z.string(),
  twitterURL: z.string(),
  defaultWebAddress: z.string(),
  webAddress: z.string(),
  fullServe: z.number(),
  hasDriveThru: z.boolean(),
  hasSeating: z.number(),
  offersAList: z.number(),
  offersCatering: z.number(),
  offersBreakfastCatering: z.boolean(),
  offersMobileOrdering: z.boolean(),
  offersMobilePayment: z.boolean(),
  offersResidentialDelivery: z.number(),
  offersWireless: z.boolean(),
  playground: z.string(),
  playgroundVal: z.number(),
  preferredEmail: z.string(),
  preferredPhone: z.string(),
  servesBreakfast: z.boolean(),
  servesLimitedMenu: z.boolean(),
  servesLunchAtBreakfast: z.boolean(),
});

export type StoreDetails = z.infer<typeof storeDetailsResponseSchema>;

export default StoreDetails;
