import { z } from 'zod';

const hoursSchema = z.object({
  segment: z.number(),
  open: z.string(),
  close: z.string(),
});

export type Hours = z.infer<typeof hoursSchema>;

const holidayHoursSchema = z.object({
  date: z.string(),
  parentId: z.string(),
  name: z.string(),
  operatorOverrideAllowed: z.boolean(),
  isClosed: z.boolean(),
  isOpen: z.boolean().optional(),
  hours: z.array(hoursSchema),
});

export type DestinationHolidayHours = z.infer<typeof holidayHoursSchema>;

const serviceHoursDay = z.object({
  isClosed: z.boolean(),
  hours: z.array(hoursSchema),
});

export type ServiceHoursDay = z.infer<typeof serviceHoursDay>;

const serviceHoursSchema = z.object({
  monday: serviceHoursDay,
  tuesday: serviceHoursDay,
  wednesday: serviceHoursDay,
  thursday: serviceHoursDay,
  friday: serviceHoursDay,
  saturday: serviceHoursDay,
  sunday: serviceHoursDay,
});

export type ServiceHours = z.infer<typeof serviceHoursSchema>;

export const destinationSchema = z.object({
  destinationType: z.string(),
  enabled: z.boolean(),
  autoCheckInEnabled: z.boolean().optional(),
  geofenceSeconds: z.number().optional(),

  // Drive Thru specific
  driveThruQRCodeEnabled: z.boolean().optional(),
  driveThruExpressEnabled: z.boolean().optional(),

  // Carry Out specific
  hasPickupShelf: z.boolean().optional(),
  kpsNotificationToggle: z.boolean().optional(),

  // Curbside specific
  withParkingSpotNumbers: z.boolean().optional(),
  curbsideAnywhere: z.boolean().optional(),
  maxPickupSlotOrders: z.number().optional(),
  ovlEnabled: z.boolean().optional(),

  specialInstructionsLine1: z.string().optional(),
  specialInstructionsLine2: z.string().optional(),
  specialInstructionsURL: z.string().optional(),
  tempDisabled: z.boolean().optional(),
  tempDisabledReason: z.string().optional(),
  autoResumeAt: z.number().optional(),
  serviceHours: serviceHoursSchema.optional(),
  holidayHours: z.array(holidayHoursSchema).optional(),
});

export type Destination = z.infer<typeof destinationSchema>;

export const personalOrderSettingsSchema = z.object({
  destinations: z.array(destinationSchema),
  personalOrderingMaximum: z.number(),
  isNotificationDelayed: z.boolean(),
  notificationDelay: z.number(),
});

export type PersonalOrderSettings = z.infer<typeof personalOrderSettingsSchema>;
